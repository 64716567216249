<template>
  <div>
    <b-container class="py-3">
      <page-title title="Admin">
        <template #action>
          <b-button variant="text" class="text-primary" @click="refreshModules">
            <b-icon-arrow-clockwise />
          </b-button>
        </template>
      </page-title>
      <b-card no-body>
        <b-overlay
          :show="
            !userModuleLoaded ||
              !gameModuleLoaded ||
              userModuleLoading ||
              gameModuleLoading
          "
          no-wrap
          spinner-variant="primary"
        ></b-overlay>
        <b-tabs card pills>
          <b-tab title="Javaslatok" active>
            <manage-requests />
          </b-tab>
          <b-tab title="Játékok">
            <games-table />
          </b-tab>

          <b-tab title="Felhasználók">
            <div class="d-none d-lg-block">
              <b-table
                :items="userModuleItems"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
              >
                <template #cell(name)="row">
                  {{ row.item.name }}
                </template>

                <template #cell(userName)="row">
                  {{ row.item.userName }}
                </template>

                <template #cell(role)="row">
                  <b-badge>
                    {{ row.item.role }}
                  </b-badge>
                </template>

                <template #cell(email)="row">
                  {{ row.item.email }}
                </template>

                <template #cell(actions)="row">
                  <b-button
                    size="sm"
                    class="mr-1"
                    variant="primary"
                    @click="edit(row.item)"
                  >
                    {{
                      currentUser.role === "admin" ||
                      currentUser.uid === row.item.uid
                        ? "Szerkesztés"
                        : "Részletek"
                    }}
                  </b-button>
                </template>
              </b-table>
            </div>
            <div class="d-block d-lg-none">
              <b-card
                v-for="user in userModuleItems.slice(
                  (currentPage - 1) * perPage,
                  currentPage * perPage
                )"
                :key="user.uid"
                class="mb-3"
              >
                <b-card-text>
                  <strong> @{{ user.userName }} </strong
                  ><b-badge>
                    {{ user.role }}
                  </b-badge>
                </b-card-text>
                <b-card-text>
                  {{ user.name }}<br />{{ user.email }}
                </b-card-text>
                <b-button
                  size="sm"
                  class="mr-1"
                  variant="primary"
                  @click="edit(user)"
                >
                  {{
                    currentUser.role === "admin" || currentUser.uid === user.uid
                      ? "Szerkesztés"
                      : "Részletek"
                  }}
                </b-button>
              </b-card>
            </div>

            <b-modal v-model="editor" title="Felhasználó szerkesztése" ok-only>
              <profile-editor
                :user="editUser"
                :readonly="
                  !currentUser ||
                    (currentUser.role !== 'admin' &&
                      editUser &&
                      currentUser.uid !== editUser.uid)
                "
              ></profile-editor>
            </b-modal>

            <b-pagination
              v-if="totalRows > perPage"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import AuthMixin from "@/mixins/AuthMixin";
import UserModuleMixin from "@/mixins/UserModuleMixin";
import GameModuleMixin from "@/mixins/GameModuleMixin";
import ProfileEditor from "../components/ProfileEditor.vue";
import PageTitle from "../components/PageTitle.vue";
import ManageRequests from "../components/ManageRequests.vue";
import GamesTable from "../components/GamesTable.vue";

export default {
  name: "Admin",
  components: { ProfileEditor, PageTitle, ManageRequests, GamesTable },
  mixins: [AuthMixin, UserModuleMixin, GameModuleMixin],
  data: () => ({
    requiredRole: "moderator",
    editor: false,
    editUser: null,
    fields: [
      {
        key: "userName",
        label: "Felhasználónév",
        sortable: true,
        sortDirection: "asc"
      },
      { key: "name", label: "Név", sortable: true },
      { key: "email", label: "Email", sortable: true },
      {
        key: "role",
        label: "Szerep",
        sortable: true
      },
      { key: "actions", label: "Műveletek", sortable: false }
    ],
    perPage: 20,
    currentPage: 1
  }),
  created() {
    this.editUser = {};
  },
  methods: {
    edit(user) {
      this.editor = true;
      this.editUser = user;
    },
    async refreshModules() {
      await this.fetchAllUsers();
      await this.fetchAllGames();
    }
  },
  computed: {
    totalRows() {
      return this.userModuleItems.length;
    }
  },
  watch: {
    userModuleItems: {
      deep: true,
      handler(items) {
        if (this.editUser)
          this.editUser = items.find((u) => u.uid === this.editUser.uid);
      }
    }
  }
};
</script>
