<template>
  <div class="wrapper">
    <b-table :items="games" :fields="fields">
      <template #cell(name)="row">
        {{ row.item.content.name }}
      </template>
      <template #cell(createdBy)="row">
        <user :uid="row.item.createdBy" />
      </template>
      <template #cell(createdAt)="row">
        {{ new Date(row.item.createdAt).toLocaleString($i18n.locale) }}
      </template>
      <template #cell(count)="row">
        {{ row.item.rating.count }}
      </template>
    </b-table>
  </div>
</template>
<script>
import GameModuleMixin from "@/mixins/GameModuleMixin";
import User from "./User.vue";
export default {
  components: { User },
  mixins: [GameModuleMixin],
  data: () => ({
    fields: [
      {
        key: "content.name",
        label: "Név",
        sortable: true,
        sortDirection: "asc"
      },
      {
        key: "createdBy",
        label: "Létrehozta",
        sortable: true
      },
      {
        key: "createdAt",
        label: "Létrehozva",
        sortable: true
      },

      {
        key: "rating.average",
        label: "★",
        sortable: true,
        formatter(v) {
          if (!v) return "";
          const formatted = new Intl.NumberFormat([], {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1
          }).format(v);
          return `${formatted} ★`;
        }
      },
      {
        key: "rating.count",
        label: "#",
        sortable: true
      }
    ]
  }),
  computed: {
    games() {
      return this.gameModuleItems.filter((g) => !!g.content);
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  overflow-x: auto;
  table {
    min-width: 800px;
  }
}
</style>
