<template>
  <div class="manageRequests">
    <div>
      <p>Jóváhagyásra vár</p>
      <p v-if="!pendingGames.length" class="text-center text-primary">
        <b-icon-check2-circle />
        Nincs jóváhagyásra váró javaslat
      </p>
      <game-requests v-for="game in pendingGames" :key="game.id" :game="game" />
    </div>
    <div>
      <p class="mt-3">Összes játék</p>
      <game-requests
        v-for="game in gameModuleItems"
        :key="game.id"
        :game="game"
        closed-panel
      />
    </div>
  </div>
</template>
<script>
import GameModuleMixin from "../mixins/GameModuleMixin";
import GameRequests from "./GameRequests.vue";
export default {
  name: "ManageRequests",
  components: { GameRequests },
  mixins: [GameModuleMixin]
};
</script>
