var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-container',{staticClass:"py-3"},[_c('page-title',{attrs:{"title":"Admin"},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('b-button',{staticClass:"text-primary",attrs:{"variant":"text"},on:{"click":_vm.refreshModules}},[_c('b-icon-arrow-clockwise')],1)]},proxy:true}])}),_c('b-card',{attrs:{"no-body":""}},[_c('b-overlay',{attrs:{"show":!_vm.userModuleLoaded ||
            !_vm.gameModuleLoaded ||
            _vm.userModuleLoading ||
            _vm.gameModuleLoading,"no-wrap":"","spinner-variant":"primary"}}),_c('b-tabs',{attrs:{"card":"","pills":""}},[_c('b-tab',{attrs:{"title":"Javaslatok","active":""}},[_c('manage-requests')],1),_c('b-tab',{attrs:{"title":"Játékok"}},[_c('games-table')],1),_c('b-tab',{attrs:{"title":"Felhasználók"}},[_c('div',{staticClass:"d-none d-lg-block"},[_c('b-table',{attrs:{"items":_vm.userModuleItems,"fields":_vm.fields,"per-page":_vm.perPage,"current-page":_vm.currentPage},scopedSlots:_vm._u([{key:"cell(name)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.name)+" ")]}},{key:"cell(userName)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.userName)+" ")]}},{key:"cell(role)",fn:function(row){return [_c('b-badge',[_vm._v(" "+_vm._s(row.item.role)+" ")])]}},{key:"cell(email)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.email)+" ")]}},{key:"cell(actions)",fn:function(row){return [_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.edit(row.item)}}},[_vm._v(" "+_vm._s(_vm.currentUser.role === "admin" || _vm.currentUser.uid === row.item.uid ? "Szerkesztés" : "Részletek")+" ")])]}}])})],1),_c('div',{staticClass:"d-block d-lg-none"},_vm._l((_vm.userModuleItems.slice(
                (_vm.currentPage - 1) * _vm.perPage,
                _vm.currentPage * _vm.perPage
              )),function(user){return _c('b-card',{key:user.uid,staticClass:"mb-3"},[_c('b-card-text',[_c('strong',[_vm._v(" @"+_vm._s(user.userName)+" ")]),_c('b-badge',[_vm._v(" "+_vm._s(user.role)+" ")])],1),_c('b-card-text',[_vm._v(" "+_vm._s(user.name)),_c('br'),_vm._v(_vm._s(user.email)+" ")]),_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.edit(user)}}},[_vm._v(" "+_vm._s(_vm.currentUser.role === "admin" || _vm.currentUser.uid === user.uid ? "Szerkesztés" : "Részletek")+" ")])],1)}),1),_c('b-modal',{attrs:{"title":"Felhasználó szerkesztése","ok-only":""},model:{value:(_vm.editor),callback:function ($$v) {_vm.editor=$$v},expression:"editor"}},[_c('profile-editor',{attrs:{"user":_vm.editUser,"readonly":!_vm.currentUser ||
                  (_vm.currentUser.role !== 'admin' &&
                    _vm.editUser &&
                    _vm.currentUser.uid !== _vm.editUser.uid)}})],1),(_vm.totalRows > _vm.perPage)?_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"align":"fill","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }